.nav-bar {
    border-top: 3px double #ccc;
    border-bottom: 3px double #ccc;
    padding: 0.25em 0;
    margin: 0 0 1em 0;

    .label {
        font-size: 0.9em;
        font-style: italic;
        color: #666;
        margin: 0 0.25em 0 1em;
        user-select: none;
    }

    .label:first-child {
        margin-left: 0;
    }
}