.Song {
  margin-top: 1em;
  
  .song-header {
    .song-title {
      font-size: 2rem;
      font-weight: 700;
    }

    .version {
      font-size: 0.875rem;
      font-weight: normal;
      padding-left: 1em;
      font-family: "Courier New", monospace;
    }

    .artist-name {
      margin: 0.125em 0 0 0;
    }

    .label {
      font-weight: lighter;
      color: #666;
    }
    .creation-date, .last-mod-date {
      margin: 0.125em 0 0 0;
      font-size: 1rem;
    }

    .creation-user, .last-mod-user {
      font-size: 0.8rem;
      font-family: "Courier New", monospace;
    }

    .label-value-section {
      margin: 1em 0 0 0;
    }

    .label-value {
      display: inline-table;

      .label {
        font-weight: 100;
        color: #666;
        margin: 0 0.25em 0 0;
      }
    }

    .label-value-sep {
      margin: 0 0.5em;
      font-weight: 100;
    }

    .label-value:nth-child(2) {
      margin-left: 0;
    }
  }

  .summary {
    padding: 0.5em;
    border: double 3px #999;
    margin: 0.75em 0 0 0;
    font-size: 0.875rem;
  }

  .chart-table {
    border-collapse: collapse;
    margin: 2em 0;
    font-size: 0.9rem;

    TR {
      TD.normal, TH {
        padding: 0.25em;
        border-bottom: 2px solid #666;
        border-right: 2px solid #666;
      }

      TD.normal {
        max-width: 1in;
        min-width: 0.6in;
        vertical-align: top;
      }

      TH {
        font-weight: 100;
        font-style: italic;
        color: #666;
        text-align: right;
        border-right: 3px double #666;
        padding-right: 0.5em;
      }
    }

    TR:last-child TD, TR:last-child TH {
      border-bottom: none;
    }

    TD.songForm, TD.bars, TD.measure {
      text-align: center;
    }

    TD.measure {
      position: relative;

      .bar-num-start, .bar-num-end {
        position: absolute;
        top: 0.25em;
      }

      .bar-num-start {
        left: 0.25em;
      }

      .bar-num-end {
        right: 0.25em;
      }
    }
  }
}

.print-footer {
  display: none;
}

// Styles for an editable song
.Song.editable {
  $hue: 208;
  $sat: 100%;
  $lgh_ta: 97%;
  $lgh_col: $lgh_ta - 5%;

  .chart-table {
    overflow: hidden;

    TD.normal {
      cursor: pointer;

      TEXTAREA {
        width: 100%;
        font-family: Roboto, monospace;
        font-size: 1rem;
        background-color: hsl($hue, $sat, $lgh_ta);
      }

      TEXTAREA.error {
        border-color: red;
      }
    }

    TD.control {
      color: #fff;
      width: 0.2em;
      border-top: 1px solid #eee;
      border-right: 2px solid #666;
      border-bottom: 1px solid #eee;
      padding: 0;
    }

    TD.measure {
      background-color: white;
    }

    col.selected {
      background-color: hsl($hue, $sat, $lgh_col);
    }

    col.add-section {
      background-color: #eee;
    }
  }
}
