* {
    margin: 0;
    padding: 0;
}

.App {
    width: 8.5in;
    height: 11in;
    background-color: #fff;
    margin: 1em auto;
    box-shadow: 3px 3px 5px #999;
    padding: 2em;
}

.breadcrumbs-and-control-bar {
    display: flex;
    justify-content: space-between;
}

.breadcrumbs {
    padding: 1em 0 0 1em;
    flex-grow: 1000;
}

.control-bar {
    flex-grow: 1;
}

.options {
    margin: 0.5em 0;
}

.prompt {
    float: left;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}
.image-and-text {
    width: 600px;
    margin: 30px;
}
.block {
    height: 300px;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.overlay-text {
    font-weight: bold;
    color: #fff;
    padding: 20px;
    text-shadow: 0 0 0.125em #000;
}
.descriptive-text {
    margin-top: 0.5em;
    font-size: 2em;
    font-weight: 300;
}
.block-drums {
    background: url('./images/close-up-photo-of-drum-set-995301.jpg') no-repeat
        center center;
    background-size: cover;
}
.block-drums .overlay-text {
    font-size: 4em;
}
.block-singer-plus-bass {
    background: url('./images/woman-playing-guitar-while-singing-beside-man-playing-bass-1309240.jpg')
        no-repeat center center;
    background-size: cover;
}
.block-singer-plus-bass .overlay-text {
    font-size: 3em;
}
.block-audience-band-blur {
    background: url('./images/audience-band-blur-colorful-613813.jpg') no-repeat
        center center;
    background-size: cover;
}
.block-audience-band-blur .overlay-text {
    font-size: 3em;
}
.block-acoustic-guitar {
    background: url('./images/fashion-woman-working-girl-34071.jpg') no-repeat
        center center;
    background-size: cover;
}
.block-acoustic-guitar .overlay-text {
    font-size: 4em;
}
.block-band-members-on-stage {
    background: url('./images/band-members-playing-on-stage-1749822.jpg')
        no-repeat center center;
    background-size: cover;
}
.block-band-members-on-stage .overlay-text {
    font-size: 3em;
}
.check {
    height: 1em;
    width: 1em;
    position: relative;
    top: 0.1em;
    margin-right: 0.75em;
}
.sample-chart {
    background: url('./images/chartInfo.png');
    width: 587px;
}

@media (max-width: 650px) {
    .image-and-text {
        width: 94vw;
        margin: 1vw 0 5vw 0;
    }
    .block .overlay-text {
        font-size: 10vw;
    }
    .block.block-drums .overlay-text {
        font-size: 16vw;
    }
    .block {
        width: 94vw;
    }
    .descriptive-text {
        width: 94vw;
        font-size: 6vw;
    }
}

@media print {
    BODY {
        background-color: #fff;
    }

    .App {
        width: 100%;
        height: 100%;
        box-shadow: none;
        margin: 0;
        padding: 0;
    }

    .breadcrumbs,
    .control-bar,
    .options,
    .song-player {
        display: none !important;
    }

    .nav-bar,
    .MuiAppBar-root {
        display: none !important;
    }

    .Song .print-footer {
        height: 24px;
        position: absolute;
        bottom: 0;
        text-align: center;
        display: block;
        width: 100%;
    }
}
